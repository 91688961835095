<template>
  <base-section :title="$t('user_management.section.budgets.title')">
    <el-row ref="budgets" :key="key" v-loading="loading">
      <el-col>
        <p class="description">
          {{ $t('user_management.section.budgets.description') }}
        </p>
      </el-col>
      <template v-for="(type, index) in types">
        <el-col :span="24" :key="index + 'checkbox'" style="max-width: 700px">
          <base-checkbox
            :label="type.name"
            :value="selectedBudgets[type.budgetType]"
            @input="v => change(type.budgetType, v)"
            :explanation="type.info"
          />
        </el-col>
      </template>
    </el-row>
  </base-section>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { keys, zipObject } from 'lodash'

export default {
  name: 'BudgetsSection',
  data () {
    return {
      loading: false,
      key: ''
    }
  },
  props: {
    value: {
      type: Array
    }
  },
  computed: {
    ...mapGetters({
      selectedCorporateAccount: 'corporateAccount/selectedCorporateAccount'
    }),
    budgetTypes () {
      return this.selectedCorporateAccount.budgetTypes
    },
    allowedBudgetTypes () {
      return this.selectedCorporateAccount.selectedBudgetTypes
    },
    selectedBudgets: {
      get () {
        const budgetTypes = this.value || []
        return zipObject(budgetTypes, new Array(budgetTypes.length).fill(true))
      }
    },
    types () {
      return (
        this.budgetTypes.filter(v =>
          this.allowedBudgetTypes.includes(v.budgetType)
        ) || []
      )
    },
    form () {
      return keys(this.selectedBudgets)
        .filter(v => this.selectedBudgets[v])
        .join(',')
    }
  },
  methods: {
    change (target, value) {
      Vue.set(this.selectedBudgets, target, value)
      this.$emit(
        'input',
        keys(this.selectedBudgets).filter(v => this.selectedBudgets[v])
      )
      this.key = this.$key()
    }
  },
  mounted () {
    if (this.types && this.types[0]) {
      this.change(this.types[0].budgetType, true)
    }
  }
}
</script>

<style scoped>
p.description {
  margin-top: 0;
}
</style>
