<template>
  <base-section :title="$t('user_management.section.extra.title')">
    <el-row :gutter="24">
      <el-col :span="12">
        <base-input
          :label="$t('general.label.external_id')"
          ref="extId"
          prop="extId"
          v-model="form.extId"
          name="extId"
          :info="$t('user_detail.external_id_explanation')"
          :validations="$v.form.extId"
        />
      </el-col>
      <el-col :span="12">
        <base-input
          :label="$t('general.label.employee_number')"
          ref="employeeNumber"
          prop="employeeNumber"
          v-model="form.employeeNumber"
          name="employeeNumber"
        />
      </el-col>
      <el-col :span="12">
        <base-input
          :label="$t('general.label.contract_number')"
          ref="contractNumber"
          prop="contractNumber"
          v-model="form.contractId"
          name="contractNumber"
          :error="error"
        />
      </el-col>
    </el-row>
  </base-section>
</template>
<script>
export default {
  name: 'ExtraSection',
  data () {
    return {
      error: ''
    }
  },
  props: {
    value: {
      type: Object
    }
  },
  computed: {
    form: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  },
  methods: {
    showInvalidContractIdError (show = true) {
      this.error = show
        ? this.$t('user_management.add_app_users_page.personal_details.invalid_contract_id')
        : ''
    }
  },
  watch: {
    'form.contractId' () {
      this.error && this.showInvalidContractIdError(false)
    }
  },
  validations () {
    return {
      form: {
        extId: {
          isValidExtId: (v) => !(/\s|\./g.test(v))
        }
      }
    }
  }
}
</script>

<style scoped></style>
