<template>
  <component
    :is="currentPage"
    :is-final="page === (this.pages.length - 1)"
    :submit-processing.sync="submitProcessing"
    @next="next"
    @back="back"
    @submit="submit"
  />
</template>

<script>

import store from '@/store'
import { mapActions, mapGetters } from 'vuex'
import PersonalDetails from '@/components/AddAppUser/PersonalDetails'
import SetBudget from '@/components/AddAppUser/SetBudget'
import Api from '@/app/services/ApiService/Api'

const SetUserGroups = () => import('@/components/AddAppUser/SetUserGroups')

export default {
  name: 'AddAppUser',
  components: {
    personal_details: PersonalDetails,
    set_budget: SetBudget,
    user_groups: SetUserGroups
  },
  data () {
    return {
      page: 0,
      pages: [
        'personal_details',
        'set_budget'
      ],
      submitProcessing: false
    }
  },
  computed: {
    ...mapGetters({
      allUserGroups: 'userGroup/all'
    }),
    currentPage () {
      return this.pages[this.page]
    }
  },

  methods: {
    ...mapActions({
      resetAppUserForm: 'user/resetAppUserForm',
      findAllUserGroups: 'userGroup/findAll',
      createAppUserAndSendCredentials: 'user/createAppUserAndSendCredentials'
    }),
    next () {
      this.page = this.page + 1
      this.$scrollTo('#app')
    },
    back () {
      if (this.page) {
        this.page = this.page - 1
      } else {
        this.$router.back()
      }
      // console.log('back')
    },
    async submit () {
      this.submitProcessing = true
      try {
        await this.createAppUserAndSendCredentials({
          ignoreErrors: ['*'],
          onSuccess: () => this.$router.push({ name: 'ManageAppUsers' }),
          onFinally: () => {
            this.submitProcessing = false
          }
        })
      } catch (e) {
        if (e.getMessage()) {
          Api.pushMessage(e.getMessage(), 'error')
        } else {
          Api.pushMessage(e.getErrorLabel(), 'error')
        }
      }
    }
  },
  created () {
    if (this.allUserGroups.length > 1 && !this.pages.includes('user_groups')) {
      this.pages.push('user_groups')
    }
  },
  beforeDestroy () {
    this.resetAppUserForm()
  },
  async beforeRouteEnter (to, from, next) {
    const corporateOspId = store.getters['corporateAccount/selectedCorporateAccountOspId']
    await Promise.all([
      store.dispatch('userGroup/findAll', corporateOspId)
    ])
    next()
  }
}
</script>

<style lang="scss">

</style>
