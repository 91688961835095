<template>
  <page-layout
    :key="key"
    :title="$t('user_management.add_app_users_page.title')"
    :sub-title="$t('user_management.add_app_users_page.set_budget.subtitle')"
    :has-back-button="true"
    :emit-back="true"
    @back="$emit('back')"
    class="add-app-user-page"
  >
    <template #header-actions>
      <base-button
        v-if="isFinal"
        key="create_user"
        :loading="submitProcessing"
        :label="$t('user_management.add_app_users_page.create_user')"
        type="primary"
        @click="handleSubmit"
      />
      <base-button
        v-else
        key="next_section"
        :loading="nextProcessing"
        :label="$t('user_management.add_app_users_page.set_budget.next_button')"
        type="primary"
        @click="handleNext"
      />
    </template>
    <el-alert type="info" :closable="false" show-icon>
      {{ alertText }}
    </el-alert>
    <template v-for="(budget, index) in budgets">
      <budget-item-section
        v-if="form[budget.budgetType]"
        :key="index"
        :id="budget.budgetType"
        :budget="budget"
        v-model="form[budget.budgetType]"
        :validations="$v.form.$each.$iter[budget.budgetType]"
      />
    </template>

    <!--    Create user and send credentials-->
    <base-section v-if="!isNoSelectedBudgetTypes">
      <div class="action-button-container">
        <base-button
          v-if="isFinal"
          key="create_user"
          :loading="submitProcessing"
          :label="$t('user_management.add_app_users_page.create_user')"
          type="primary"
          @click="handleSubmit"
        />
        <base-button
          v-else
          key="next_section"
          :loading="nextProcessing"
          :label="
            $t('user_management.add_app_users_page.set_budget.next_button')
          "
          type="primary"
          @click="handleNext"
        />
      </div>
    </base-section>
  </page-layout>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { get, forIn, forEach } from 'lodash'
import PageLayout from '../../layouts/PageLayout'
import BudgetItemSection from '../sections/BudgetItemSection'

import Budget from '@/app/models/Budget'

export default {
  name: 'SetBudget',
  components: {
    PageLayout,
    BudgetItemSection
  },
  props: {
    isFinal: {
      type: Boolean,
      default: false
    },
    submitProcessing: {
      type: Boolean,
      default: false
    }
  },
  validations () {
    return {
      form: {
        $each: {
          frequencyAmount: {
            required
          }
        }
      }
    }
  },
  data () {
    return {
      key: '',
      nextProcessing: false
    }
  },
  computed: {
    ...mapGetters({
      corporateAccount: 'corporateAccount/selectedCorporateAccount',
      appUserForm: 'user/appUserForm'
    }),
    budgets () {
      return (
        this.corporateAccount.budgetTypes.filter(v =>
          this.selectedBudgetTypes.includes(v.budgetType)
        ) || []
      )
    },
    selectedBudgetTypes () {
      return get(this.appUserForm, 'budgetTypes', [])
    },
    isNoSelectedBudgetTypes () {
      return this.selectedBudgetTypes.length === 0
    },
    alertText () {
      return this.isNoSelectedBudgetTypes
        ? this.$t('user_management.add_app_users_page.set_budget.no_selected_budgets_alert_text')
        : this.$t('user_management.add_app_users_page.set_budget.alert_text')
    },
    form: {
      get () {
        return get(this.appUserForm, 'userBudgets')
      },
      set (v) {
        this.setAppUserForm({
          ...this.appUserForm,
          userBudgets: v
        })
      }
    }
  },
  methods: {
    ...mapActions({
      setAppUserForm: 'user/setAppUserForm'
    }),
    handleSubmit () {
      if (this.validate()) {
        this.$emit('submit')
      }
    },
    handleNext () {
      if (this.validate()) {
        this.nextProcessing = true
        this.$emit('next')
      }
    },
    validate () {
      if (this.isNoSelectedBudgetTypes) {
        return true
      }
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        return true
      }
      const invalid = []
      forIn(this.$v.form.$each.$iter, (value, key) => {
        if (value.$invalid) {
          invalid.push(key)
        }
      })

      this.$scrollTo('#' + invalid[0])
    }
  },
  created () {
    forEach(this.budgets, budget => {
      if (!this.form[budget.budgetType]) {
        this.form[budget.budgetType] = new Budget({
          data: { type: budget.budgetType }
        })
      }
    })
    this.key = this.$key()
  }
}
</script>

<style lang="scss"></style>
