<template>
  <base-section :title="$t('user_management.section.account.title')" class="account-section">
    <el-row :gutter="24">
      <el-col :key="key" :span="12">
        <base-checkbox
          v-if="isAddUserPage"
          :label="$t('user_management.section.account.checkbox')"
          v-model="defaultLogin"
        />
        <base-input
          :label="$t('general.label.login_name')"
          :disabled="defaultLogin"
          ref="login"
          prop="login"
          :error="error"
          v-model="form.login"
          :info="$t('user_management.section.account.login_name_explanation')"
          name="login"
          @submit="$emit('submit')"
        />
      </el-col>
      <el-col :span="12">
        <account-activation-section
          v-model="form.desiredActivationDate"
          :picker-options="desiredActivationDatePickerOptions"
        />
        <account-activation-section
          ref="deactivation"
          v-model="form.desiredDeactivationDate"
          :picker-options="desiredDeactivationDatePickerOptions"
          deactivation
        />
      </el-col>
    </el-row>

    <template v-if="extraButton">
      <base-button
        v-if="showExtra"
        class="extra-details-button"
        :label="$t('user_management.section.personal.hide_extra_details_button')"
        prepend-icon="ri-arrow-up-s-line"
        @click.native="toggleExtraDetails"
      />
      <base-button
        v-else
        class="extra-details-button"
        :label="$t('user_management.section.personal.add_extra_details_button')"
        prepend-icon="ri-arrow-down-s-line"
        @click.native="toggleExtraDetails"
      />
    </template>
  </base-section>
</template>

<script>
import AccountActivationSection from './AccountActivationSection'

export default {
  name: 'AccountSection',
  components: { AccountActivationSection },
  data () {
    return {
      defaultLogin: false,
      key: '',
      error: '',
      isAddUserPage: false
    }
  },
  props: {
    value: {
      type: Object
    },
    showExtra: {
      type: Boolean,
      default: false
    },
    extraButton: {
      type: Boolean,
      default: true
    },
    email: {
      type: String,
      default: null
    }
  },
  watch: {
    email (v) {
      if (this.defaultLogin) {
        this.setDefaultLogin()
      }
    },
    'form.login' () {
      this.error && this.showLoginExistsError(false)
    },
    'form.desiredActivationDate' (v) {
      if (this.$refs.deactivation.type && v) {
        this.form.desiredDeactivationDate = this.$date.fromISO(v).plus({ days: 1 }).toISODate()
        this.$refs.deactivation.dateKey = this.$key()
      }
    },
    'form.desiredDeactivationDate' (v) {
      if (this.form.desiredActivationDate && v) {
        if (this.$date.fromISO(v) <= this.$date.fromISO(this.form.desiredActivationDate)) {
          this.form.desiredDeactivationDate = this.$date
            .fromISO(this.form.desiredActivationDate)
            .plus({ days: 1 })
            .toISODate()
        }
      }
    }
  },
  computed: {
    form: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
        // this.showLoginExistsError(false)
      }
    },
    desiredActivationDatePickerOptions () {
      return {
        disabledDate: value => {
          if (this.form.desiredDeactivationDate) {
            return (
              value >=
                this.$date.fromSQL(this.form.desiredDeactivationDate).startOf('day').toJSDate() ||
              value < this.$date.local().plus({ days: 1 }).startOf('day')
            )
          }
          return value < this.$date.local().plus({ days: 1 }).startOf('day')
        }
      }
    },
    desiredDeactivationDatePickerOptions () {
      return {
        disabledDate: value => {
          if (this.form.desiredActivationDate) {
            return (
              value <
                this.$date
                  .fromSQL(this.form.desiredActivationDate)
                  .plus({ days: 1 })
                  .startOf('day')
                  .toJSDate() || value < this.$date.local().plus({ days: 2 }).startOf('day')
            )
          }
          return value < this.$date.local().startOf('day')
        }
      }
    }
  },
  methods: {
    showLoginExistsError (show = true) {
      this.error = show ? this.$t('validations.login_exists') : ''
    },
    toggleExtraDetails () {
      this.$emit('update:showExtra', !this.showExtra)
    },
    setDefaultLogin () {
      this.form.login = this.email || ''
      this.key = this.$key()
    }
  },
  mounted () {
    this.isAddUserPage = this.$router.history.current.name === 'AddAppUser'
    this.defaultLogin = this.isAddUserPage && this.email === this.form.login
    this.key = this.$key()
    this.$watch('defaultLogin', v => {
      if (v) {
        this.setDefaultLogin()
      } else {
        this.form.login = ''
        this.key = this.$key()
      }
    })
  }
}
</script>

<style scoped></style>
