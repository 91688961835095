<template>
  <page-layout
    :title="$t('user_management.add_app_users_page.title')"
    :sub-title="
      $t('user_management.add_app_users_page.personal_details.subtitle')
    "
    :has-back-button="true"
    :emit-back="true"
    @back="$emit('back')"
    class="add-app-user-page"
  >
    <!--    Create user and send credentials-->
    <template #header-actions>
      <base-button
        :label="
          $t('user_management.add_app_users_page.personal_details.next_button')
        "
        :loading="nextProcessing"
        type="primary"
        @click="handleSubmit"
      />
    </template>
    <el-alert type="info" :closable="false" show-icon>
      {{ $t('user_management.add_app_users_page.personal_details.alert_text') }}
    </el-alert>
    <el-form
      ref="form"
      :model="form"
      @submit.prevent.native="handleSubmit"
      :key="key"
      autocomplete="off"
    >
      <personal-section
        v-model="form.personalForm"
        id="personalSection"
        ref="personalSection"
        @submit="handleSubmit"
      />
      <account-section
        ref="account"
        id="accountSection"
        v-model="form.accountForm"
        :email="form.personalForm.email"
        :show-extra.sync="form.showExtra"
        @submit="handleSubmit"
      />
      <template v-if="form.showExtra">
        <extra-section
          id="extraDetailsSection"
          v-model="form.extraForm"
          ref="extraDetailsSection"
        />
        <address-section
          id="homeAddressSection"
          v-model="form.homeAddressForm"
          ref="homeAddressSection"
          type="home"
          @submit="handleSubmit"
        />
        <address-section
          v-model="form.workAddressForm"
          id="workAddressSection"
          type="work"
          ref="workAddressSection"
          @submit="handleSubmit"
        />
      </template>
      <budgets-section
        v-model="form.budgetTypes"
        ref="budgetsSection"
        id="budgetsSection"
      />
    </el-form>
    <base-section>
      <div class="action-button-container">
        <base-button
          :label="
            $t(
              'user_management.add_app_users_page.personal_details.next_button'
            )
          "
          :loading="nextProcessing"
          type="primary"
          @click="handleSubmit"
        />
      </div>
    </base-section>
  </page-layout>
</template>

<script>
import PageLayout from '@/layouts/PageLayout'
import PersonalSection from '@/components/sections/PersonalSection'
import AccountSection from '@/components/sections/AccountSection'
import ExtraSection from '@/components/sections/ExtraSection'
import AddressSection from '@/components/sections/AddressSection'
import BudgetsSection from '@/components/sections/BudgetsSection'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PersonalDetails',
  components: {
    PageLayout,
    PersonalSection,
    AccountSection,
    ExtraSection,
    AddressSection,
    BudgetsSection
  },
  data () {
    return {
      isValidLogin: false,
      isValidContractId: false,
      key: '',
      nextProcessing: false,
      form: {
        showExtra: false,
        personalForm: {
          firstname: '',
          lastname: '',
          email: '',
          msisdn: '',
          birthdate: '',
          language: 'nl'
        },
        accountForm: {
          login: '',
          desiredActivationDate: null,
          desiredDeactivationDate: null
        },
        extraForm: {
          employeeNumber: '',
          contractId: '',
          extId: null
        },
        budgetTypes: [],
        workAddressForm: {
          workStreet: '',
          workStreetNumber: '',
          workZipcode: '',
          workCity: ''
        },
        homeAddressForm: {
          homeStreet: '',
          homeStreetNumber: '',
          homeZipcode: '',
          homeCity: ''
        }
      }
    }
  },
  props: {
    submitProcessing: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      appUserForm: 'user/appUserForm',
      corporateAccountOspId: 'corporateAccount/selectedCorporateAccountOspId'
    })
  },
  methods: {
    ...mapActions({
      setAppUserForm: 'user/setAppUserForm'
    }),
    async handleSubmit () {
      if (!this.validateForms()) {
        return false
      }
      this.nextProcessing = true
      try {
        !this.isValidLogin && (await this.verifyLogin())
        !this.isValidContractId && (await this.verifyContractId())
      } catch (e) {
        console.error(e)
        this.nextProcessing = false
        return false
      }
      this.setAppUserForm(this.form)
      this.$emit('next')
    },
    validateForms () {
      this.$refs.personalSection.$v.form.$touch()
      if (this.form.showExtra) {
        this.$refs.homeAddressSection.$v.form.$touch()
        this.$refs.workAddressSection.$v.form.$touch()
        this.$refs.extraDetailsSection.$v.form.$touch()
      }
      if (this.$refs.personalSection.$v.form.$invalid) {
        this.$scrollTo('#personalSection')
        return false
      }
      if (
        this.form.showExtra &&
        this.$refs.homeAddressSection.$v.form.$invalid
      ) {
        this.$scrollTo('#homeAddressSection')
        return false
      }
      if (
        this.form.showExtra &&
        this.$refs.workAddressSection.$v.form.$invalid
      ) {
        this.$scrollTo('#workAddressSection')
        return false
      }
      if (
        this.form.showExtra &&
        this.$refs.extraDetailsSection.$v.form.$invalid
      ) {
        this.$scrollTo('#extraDetailsSection')
        return false
      }
      return true
    },
    async verifyLogin () {
      if (!this.form.accountForm.login) {
        this.isValidLogin = true
        return true
      }
      await this.$api.user.verifyLogin(this.form.accountForm.login, {
        onSuccess: res => {
          this.isValidLogin = !res.exists
        }
      })
      if (!this.isValidLogin) {
        this.$refs.account.showLoginExistsError()
        this.$scrollTo('#accountSection')
        throw new Error('Invalid login')
      }
      return true
    },
    async verifyContractId () {
      await this.$api.user.verifyContractId(
        {
          contractId: this.form.extraForm.contractId,
          corporateAccountOspId: this.corporateAccountOspId
        },
        {
          onSuccess: res => {
            this.isValidContractId = res.answer
          }
        }
      )
      if (!this.isValidContractId) {
        !this.form.showExtra && (this.form.showExtra = true)
        this.$nextTick(() => {
          this.$refs.extraDetailsSection.showInvalidContractIdError()
          this.$scrollTo('#extraDetailsSection')
        })
        throw new Error('Invalid contract number')
      }
    }
  },
  mounted () {
    this.form = this.appUserForm
    this.key = this.$key()
  }
}
</script>

<style lang="scss"></style>
